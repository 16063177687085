.body {
  margin: 0;
  font-size: 12px;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}
.liNoBullet{
  list-style:none;
}
.colorFondo{
  color:maroon;
}
.colorBlanco{
  background-color: white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.centrado{
  text-align: center;
}
.celdaFecha{
  width: 80px;
}
.celdaBoton{
  width: 35px;
  text-align: center;
}
.celdaCh{
  width: 40px;
}
.celdaMed{
  width: 100px;
}
.celdaGde{
  width: 200px;
}
.celdaNombre{
  width: 350px;
}
.numeroCeldaMin{
  padding: 3px;
  width: 40px;
  text-align: right;
}
.numeroCeldaCh{
  width: 50px;
  text-align: right;
}
.numeroCeldaMed{
  width: 100px;
  text-align: right;
}
.numeroCeldaGde{
  width: 150px;
  text-align: right;
}
.numero{
  text-align: right;
}
.total{
  text-align: right;
  font-size:16px;
}
.rojo{
 color:maroon;
 font-weight: bold; 
}
.verde{
  color:green;
  font-weight: bold; 
 } 
.negro{
  color:black;
  font-weight: bold; 
 }
 .opcion{
   font-weight: bold;
  color:maroon;
}
.opcion:hover{
  background-color:maroon;
  color:white;
}

.form-control-sm{
  height:calc(1.2em + .5rem + 2px);
}
.btn-sm{
  padding:.25rem .5rem;
  font-size:.875rem;
  line-height:1.2;
  border-radius:.2rem
}
.input-group-text{
  height:calc(1.2em + .5rem + 0px);
}
.imgLink{
  cursor: pointer;
}
.opcionActual{
  text-align: center;
  font-size: medium;
  font-weight: bold;
  color:maroon;
}
.opcionMenu {
  list-style:none;
  font-size:12px;
  padding-left: 10px;
}
.opcionMenu::before {content: "• "; color: maroon}
.liMenu:hover {
  background-color:silver;
  color:maroon;
}
.caja{
  padding: 10px;
  color:black;
  background-color: whitesmoke;
  box-shadow: rgba(255, 255, 255, 0.35) 10px 15px 15px;
}